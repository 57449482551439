<template>
  <header class="header">
    
    <div class="nav-container">
      <router-link :to="{ name: 'home' }">
        <img class="logo" src="../../assets/invrs-logo-white-full.png" />
      </router-link>
      <div class="options" @click="toggleMenu">
        <span class="top-line"></span>
        <span class="bottom-line"></span>
      </div>
    </div>
  </header>
  <transition>
    <mobile-menu
      class="menu"
      v-show="menuOpen"
      @menuItemClick="toggleMenu"
      @your-journey-option-click="yourJourneyOptionClick"
    ></mobile-menu
  ></transition>
</template>

<script>
import MobileMenu from "./MobileMenu.vue";

export default {
  components: {
    "mobile-menu": MobileMenu,
  },
  data() {
    return {
      topLine: "",
      bottomLine: "",
      menuOpen: false,
    };
  },
  mounted() {
    this.topLine = document.querySelector(".top-line");
    this.bottomLine = document.querySelector(".bottom-line");
  },
  methods: {
    toggleMenu() {
      this.topLine.classList.toggle("open-top-line");
      this.bottomLine.classList.toggle("open-bottom-line");
      this.menuOpen = !this.menuOpen;
    },
    yourJourneyOptionClick(id) {
      this.toggleMenu();

      this.$router.push({ name: "home" }).then(() => {
        const section = document.querySelector(id);
        section.scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  padding: 0 3.2rem;
}

.banner {
  background-color: #693bf5; 
  padding: 1rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-text, .banner-text a, .banner-text a:active, .banner-text a:visited {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.2;
}

.banner-text a {
  text-decoration: underline;
}

.banner-text a:hover, .banner-text a:focus {
  color: #3c9;
}

.logo {
  height: 4.4rem;
}

.options {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

span {
  background: #333;
  height: 3px;
  width: 28px;
  transition: all 0.2s ease-in;
}

.top-line {
  margin-bottom: 0.8rem;
}

.open-top-line {
  transform: rotate(45deg) translate(3px, 4px);
}

.open-bottom-line {
  transform: rotate(-45deg) translate(3px, -4px);
}

.menu {
  margin-top: 13.8rem;
  position: fixed;
  z-index: 998;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(1.2rem);
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease-in;
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .menu {
    margin-top: 64px;
  }
}


/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .nav-container {
    padding: 0 2.4rem;
  }

  .banner {
    padding: 1rem 2.4rem;
  }

  .logo {
    height: 4rem;
  }

}
</style>
