<template>
  <section>
    <the-mobile-header v-if="showMobileHeader"></the-mobile-header>
    <the-header v-else></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </section>
</template>

<script>
import TheFooter from "./components/nav/TheFooter.vue";
import TheHeader from "./components/nav/TheHeader.vue";
import TheMobileHeader from "./components/nav/TheMobileHeader.vue";

export default {
  components: {
    "the-footer": TheFooter,
    "the-header": TheHeader,
    "the-mobile-header": TheMobileHeader,
  },
  data() {
    return {
      showMobileHeader: false,
    };
  },
  created() {
    const body = document.querySelector("body");
    const windowObserver = new ResizeObserver(() => {
      if (window.innerWidth <= 800) {
        this.showMobileHeader = true;
      } else {
        this.showMobileHeader = false;
      }
    });
    windowObserver.observe(body);
    // if( window.location.host.includes("fintrivia.com")){
    //   this.$router.push('/fintrivia');
    // }
  },
  mounted() {
    const source = this.$route.query.utm_source;

    if (source) {
      document.cookie = "affiliate.referral.id" + '=' + source + '; Path=/; Domain=invrs.com';
    }

    
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html *
{
   
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: "Inter", sans-serif;
  line-height: 1;
  font-weight: 400;
  font-size: 2.2rem;
  overflow-x: hidden;
  color: #4d4d4d;
}

.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.heading-primary {
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 1.05;
  margin: 0 0 2.4rem 0;
  letter-spacing: -0.5px;
  color: #333;
}

.heading-secondary {
  font-size: 4.8rem;
  color: #333;
  margin-bottom: 4.8rem;
  line-height: 1.1;
}

.heading-tertiary {
  font-size: 2.4rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.subheading {
  font-size: 2.4rem;
  margin-bottom: 3.2rem;
  font-weight: 500;
  color: rgb(105, 59, 245, 1);
  display: inline-block;
}

.btn,
.btn:visited {
  display: inline-block;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: 1.8rem 3.6rem;
  border-radius: 8px;
  cursor: pointer;

  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );

  transition: all 0.2s ease;
}

.btn:hover,
.btn:active {
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
}

.grid {
  display: grid;
  row-gap: 3.2rem;
  column-gap: 3.2rem;
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--12-cols {
  grid-template-columns: repeat(12, 1fr);
}

li:has( a.router-link-exact-active) {
    /* text-decoration: underline !important; */
    border-bottom: solid 1px #693bf56b;
}

/* **************************** */
/* BELOW 1200px (laptop) */
/* **************************** */
@media (max-width: 75em) {
  html {
    /* changing what 1 rem is - 9px / 16px = 56.25% */
    font-size: 56.25%;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  html {
    font-size: 50%;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading-secondary {
    font-size: 4rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .grid--3-cols {
    grid-template-columns: 1fr;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .heading-secondary {
    font-size: 3rem;
  }

  .subheading {
    font-size: 2rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .container {
    padding: 0 2.4rem;
  }

  li:has( a.router-link-exact-active) {
    /* text-decoration: underline !important; */
    border-bottom: none;
  }

  li.nav-link-container > a.router-link-exact-active {
    border-bottom: solid 1px #693bf56b;
  }


}
</style>
